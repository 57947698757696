import { Routes, Route, useNavigate } from "react-router-dom";
import { MainPage } from "../pages";
export default function AppRoutes() {
  const navigate = useNavigate();
  return (
    <Routes navigate={navigate}>
      <Route path="/" element={<MainPage />}></Route>
    </Routes>
  );
}
