const version = "v1.0.0";

const ENV = {
    dev: {
        env: 'development',
        appVersion: `${version} [Dev]`,
        apiUrl: '',
        wakaManagement:'https://wakamgmt.brvtechnologysg.com',
        serviceAccountApi: 'OTM3tAh3TloYm8I67G3V0ksmNxLLbYmd',
    },
    devlocal: {
        env: 'local',
        appVersion: `${version} [Dev Local]`,
        apiUrl: '',
        wakaManagement:'https://wakamgmt.brvtechnologysg.com',
        serviceAccountApi: 'OTM3tAh3TloYm8I67G3V0ksmNxLLbYmd',
    },
    prod: {
        env: 'production',
        appVersion: version,
        apiUrl: '',
        wakaManagement:'https://wakamgmt.brvtechnologysg.com',
        serviceAccountApi: 'OTM3tAh3TloYm8I67G3V0ksmNxLLbYmd',
    },
}

export const getEnvVars = () => {
    let apiEndpoint = ENV.devlocal
    if (window.location.href.includes("localhost")) {
      apiEndpoint = ENV.devlocal
    }else{
        if(window.location.href.includes("waka-")){
            apiEndpoint = ENV.dev
        }else{
            apiEndpoint = ENV.prod
        }
    }
    return apiEndpoint;
};