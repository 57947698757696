import * as React from 'react';
import "./styles.scss";
import { Button } from '@mui/material';

const AppButton = ({text,variant="contained",color="lighRed",onClick,btnConClassName="",btnClass="",icon,iconPossition="left"}) => {
  return (
    <div className={`${btnConClassName}`}>
      {
        text && (
            <Button variant={variant} color={color} className={`${btnClass} cust_btn`} font-inter onClick={onClick}>{iconPossition ==="left"&&icon}{text}{iconPossition ==="right"&&icon}</Button>
        )
      }
    </div>
    
  );
}

export default AppButton;
//btnConClassName: button container class
//cust_btn : button add style or override
//text: button label string
//variant: [text,contained,outlined]
//color: [...built-in of mui,lighRed]
//onClick: button click function