import { useRef, useState, forwardRef, useEffect } from "react";
import "./styles.scss";
import wakaLight from "../../assets/images/waka_light.png";
import iconSettings from "../../assets/images/iconSettings.png";
import iconMoneyInEnvelope from "../../assets/images/iconMoneyInEnvelope.png";
import iconLoan from "../../assets/images/iconLoan.png";
import iconBankIntegration from "../../assets/images/iconBankIntegration.png";
import keyFeatureImg1 from "../../assets/images/keyFeatureImg1.png";
import keyFeatureImg2 from "../../assets/images/keyFeatureImg2.png";
import keyFeatureImg3 from "../../assets/images/keyFeatureImg3.png";
import keyFeatureImg4 from "../../assets/images/keyFeatureImg4.png";
import timeClock from "../../assets/images/timeClock.png";
import user_friendly_dashboard from "../../assets/images/user_friendly_dashboard.png";
import integration_biometric_devices from "../../assets/images/integration_biometric_devices.png";
import face_recognition_technology from "../../assets/images/face_recognition_technology.png";
import reports_analytics from "../../assets/images/reports_analytics.png";
import leave_management from "../../assets/images/leave_management.png";
import waka_dashboard from "../../assets/images/waka_dashboard.png";
import AppButton from "../../components/AppButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TextField from "@mui/material/TextField";
import Slider from "react-slick";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { ApiCall, ApiMethod } from "../../services/AxiosInstance";
import { getEnvVars } from "../../services/Environment";
const { serviceAccountApi, wakaManagement } = getEnvVars();
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function MainPage() {
  const ref = useRef(null);
  const [callingApi, setCallingApi] = useState(false);
  const [form, setForm] = useState({
    name: "",
    comName: "",
    email: "",
    phoneNumber: "",
    note: "",
    formState: false,
  });
  const [snackMessage, setSnackMessage] = useState({
    message: "",
    state: false,
  });
  const reqDemo = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneNumberRegex =/^[0-9 -+]+$/;
  const saveForm = async () => {
    if (!form.name) {
      setSnackMessage({
        message: "Please fill name field",
        severity: "error",
        state: true,
      });
    } else if (!form.comName) {
      setSnackMessage({
        message: "Please fill company field",
        severity: "error",
        state: true,
      });
    } else if (!emailRegex.test(form.email)) {
      setSnackMessage({
        message: "Please fill valid email ",
        severity: "error",
        state: true,
      });
    } else if (!form.phoneNumber) {
      setSnackMessage({
        message: "Please fill phone number ",
        severity: "error",
        state: true,
      });
    }else if (!phoneNumberRegex.test(form.phoneNumber)) {
      setSnackMessage({
        message: "Please fill valid phone number ",
        severity: "error",
        state: true,
      });
    } else if (!form.note) {
      setSnackMessage({
        message: "Please fill note field",
        severity: "error",
        state: true,
      });
    } else {
      setCallingApi(true);
      try {
        await ApiCall({
          apiEndpoint: `${wakaManagement}/items/waka_demo_form`,
          method: ApiMethod.POST,
          token: serviceAccountApi,
          apiData: {
            name: form.name,
            company_name: form.comName,
            email: form.email,
            phone_number: form.phoneNumber,
            note: form.note,
          },
        });
        setSnackMessage({
          message: "Form submitted successfully",
          severity: "success",
          state: true,
        });
        setForm({
          ...form,
          name: "",
          comName: "",
          email: "",
          phoneNumber: "",
          note: "",
          formState: true,
        });
        setCallingApi(false);
      } catch (error) {
        setSnackMessage({
          message: "Form submitted failed",
          severity: "success",
          state: true,
        });
        setCallingApi(false);
        console.log(error);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackMessage({
      message: "",
      severity: "",
      state: false,
    });
  };

  const wakaFeatureSettings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 981,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          //cssEase: "linear"
        },
      },

      {
        breakpoint: 641,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          //cssEase: "linear"
        },
      },
    ],
  };

  return (
    <>
      <section id="hero_section" className="section">
        <div className="my_container">
          <img src={wakaLight} alt="" className="waka_logo" />
          <div className="d_block">
            <p className="small_lbl">
              Simplify your payroll processes and focus on what matters most -
              growing your business.
            </p>
          </div>

          <h1>
            Automated <span className="colorRed">Payroll</span> Processing &
            Employee <span className="colorRed">Management</span>
          </h1>
          <p className="desc">
            Say goodbye to manual calculations. Our app automates payroll,
            saving you time and reducing errors.
          </p>
          <div className="d_block">
            <AppButton
              text={"Request Demo"}
              icon={<ArrowForwardIcon />}
              iconPossition="right"
              color="error"
              onClick={reqDemo}
            />
          </div>

          <div className="waka_features">
            <h4 className="title">Waka Features</h4>
            <Slider {...wakaFeatureSettings} className="waka_features_slider">
              <div className="features_item">
                <img src={iconSettings} alt="" />
                <h5>Set Up Leave</h5>
              </div>
              <div className="features_item">
                <img src={iconMoneyInEnvelope} alt="" />
                <h5>Payroll</h5>
              </div>
              <div className="features_item">
                <img src={iconLoan} alt="" />
                <h5>Loan</h5>
              </div>
              <div className="features_item">
                <img src={iconBankIntegration} alt="" />
                <h5>Easy Bank Integration</h5>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      <section id="keyFeatures_section" className="section">
        <div className="my_container">
          <h2 className="title">
            Key <span className="colorRed">Features</span>
          </h2>
          <div className="key_features_items">
            <div className="key_features_item">
              <div className="text-con">
                <div className="num_det">
                  <div className="num">
                    <h4>01</h4>
                  </div>
                  <div className="details">
                    <h4>Automated Payroll Processing</h4>
                    <ul>
                      <li>
                        Eliminate manual calculations and reduce errors with our
                        automated payroll processing.
                      </li>
                      <li>
                        Streamline salary, tax, and deduction calculations for a
                        hassle-free payroll experience.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="num_det">
                  <div className="num">
                    <h4>02</h4>
                  </div>
                  <div className="details">
                    <h4>Employee Management</h4>
                    <ul>
                      <li>
                          Maintain accurate and up-to-date employee records, including personal information, employment contracts, and performance evaluations.
                      </li>
                      <li>
                          Ensure compliance with data protection and privacy regulations.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="img_con">
                <img src={keyFeatureImg1} alt="" />
              </div>
            </div>
            <div className="key_features_item">
              <div className="text-con">
                <div className="num_det">
                  <div className="num">
                    <h4>03</h4>
                  </div>
                  <div className="details">
                    <h4>Tax Management</h4>
                    <ul>
                      <li>
                        Simplify tax compliance with automated tax calculations
                        based on the latest regulations.
                      </li>
                      <li>
                        Generate comprehensive tax reports for easy submission
                        to relevant authorities.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="num_det">
                  <div className="num">
                    <h4>04</h4>
                  </div>
                  <div className="details">
                    <h4>Time Tracking</h4>
                    <ul>
                      <li>
                        Implement efficient time tracking with clock-in and
                        clock-out features.
                      </li>
                      <li>
                        Easily manage and monitor employee timesheets for
                        accurate attendance and payroll calculations.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="img_con">
                <img src={keyFeatureImg2} alt="" />
              </div>
            </div>
            <div className="key_features_item">
              <div className="text-con">
                <div className="num_det">
                  <div className="num">
                    <h4>05</h4>
                  </div>
                  <div className="details">
                    <h4>Reports and Analytics</h4>
                    <ul>
                      <li>
                        Access real-time payroll reports and analytics to gain
                        valuable insights into your payroll data.
                      </li>
                      <li>
                        Customize reports to meet specific business needs,
                        facilitating better decision-making.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="num_det">
                  <div className="num">
                    <h4>06</h4>
                  </div>
                  <div className="details">
                    <h4>Security and Compliance</h4>
                    <ul>
                      <li>
                        Ensure the security of sensitive payroll data with
                        advanced security features.
                      </li>
                      <li>
                        Stay compliant with local labor laws and regulations,
                        reducing legal risks.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="img_con">
                <img src={keyFeatureImg3} alt="" />
              </div>
            </div>
            <div className="key_features_item">
              <div className="text-con">
                <div className="num_det">
                  <div className="num">
                    <h4>07</h4>
                  </div>
                  <div className="details">
                    <h4>Bank Integration</h4>
                    <ul>
                      <li>
                        Ensuring compliance with banking and financial
                        regulations.
                      </li>
                      <li>Adhering to data protection and privacy standards</li>
                    </ul>
                  </div>
                </div>
                <div className="num_det">
                  <div className="num">
                    <h4>08</h4>
                  </div>
                  <div className="details">
                    <h4>Waka Wallet</h4>
                    <ul>
                      <li>Up Coming Feature</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="img_con">
                <img src={keyFeatureImg4} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="statistic_section" className="section">
        <div className="my_container">
          <h4 className="details">
            Welcome to our Clockwise Attendance Tracker System, a comprehensive
            solution designed to streamline and manage employee attendance
            efficiently. This system aims to simplify the process of tracking
            working hours, leave requests, and overall attendance management.
          </h4>
          <div className="statistics_items">
            <div className="statistics_item">
              <div className="num">
                <h2>10+</h2>
              </div>
              <div className="label">
                <p>Statistics Number Here</p>
              </div>
            </div>
            <div className="statistics_item">
              <div className="num">
                <h2>666+</h2>
              </div>
              <div className="label">
                <p>Statistics Number Here</p>
              </div>
            </div>
            <div className="statistics_item">
              <div className="num">
                <h2>555+</h2>
              </div>
              <div className="label">
                <p>Statistics Number Here</p>
              </div>
            </div>
            <div className="statistics_item">
              <div className="num">
                <h2>10+</h2>
              </div>
              <div className="label">
                <p>Statistics Number Here</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section id="tracking_section" className="section">
        <div className="my_container">
          <div className="text_con">
            <h2 className="title">
            Attendance<span className="colorRed" style={{display:'block'}}>Tracker System</span>
            </h2>
            <p>
              Welcome to our Clockwise Attendance Tracker System, a
              comprehensive solution designed to streamline and manage employee
              attendance efficiently. This system aims to simplify the process
              of tracking working hours, leave requests, and overall attendance
              management.
            </p>
          </div>
          <div className="img_con">
            <img src={waka_dashboard} alt="" />
          </div>
        </div>
      </section>
      <section id="clockwise_section" className="section">
        <div className="my_container">
          <h2 className="title">
            Clock<span className="colorRed">wise</span>
          </h2>
          <div className="clock_wise_feature_items">
            <div className="clock_wise_feature_item">
              <div className="heading">
                <div className="img">
                  <img src={timeClock} alt="" />
                </div>
                <div className="title_head">
                  <p>Real-time Attendance Tracking</p>
                </div>
              </div>
              <div className="desc">
                <ul>
                  <li>
                    Instantaneous updates on employee attendance status through
                    face recognition.
                  </li>
                  <li>Seamless integration with our user-friendly interface</li>
                </ul>
              </div>
            </div>
            <div className="clock_wise_feature_item">
              <div className="heading">
                <div className="img">
                  <img src={user_friendly_dashboard} alt="" />
                </div>
                <div className="title_head">
                  <p>User-Friendly Dashboard</p>
                </div>
              </div>
              <div className="desc">
                <ul>
                  <li>Intuitive dashboard for employees and administrators.</li>
                  <li>
                    Quick overview of daily, weekly, and monthly attendance
                    trends.
                  </li>
                </ul>
              </div>
            </div>
            <div className="clock_wise_feature_item">
              <div className="heading">
                <div className="img">
                  <img src={leave_management} alt="" />
                </div>
                <div className="title_head">
                  <p>Leave Management</p>
                </div>
              </div>
              <div className="desc">
                <ul>
                  <li>
                    Streamlined leave request submission with facial biometric
                    authentication.
                  </li>
                  <li>
                    Detailed leave balances and history at your fingertips.
                  </li>
                </ul>
              </div>
            </div>
            <div className="clock_wise_feature_item">
              <div className="heading">
                <div className="img">
                  <img src={reports_analytics} alt="" />
                </div>
                <div className="title_head">
                  <p>Reports and Analytics</p>
                </div>
              </div>
              <div className="desc">
                <ul>
                  <li>
                    Generate in-depth reports on individual and team attendance.
                  </li>
                  <li>
                    Utilize analytics to identify patterns and optimize
                    workforce management.
                  </li>
                </ul>
              </div>
            </div>
            <div className="clock_wise_feature_item">
              <div className="heading">
                <div className="img">
                  <img src={face_recognition_technology} alt="" />
                </div>
                <div className="title_head">
                  <p>Face Recognition Technology</p>
                </div>
              </div>
              <div className="desc">
                <ul>
                  <li>
                    Secure and contactless attendance tracking through facial
                    biometrics.
                  </li>
                  <li>
                    Enhanced accuracy in recording clock-in and clock-out times.
                  </li>
                </ul>
              </div>
            </div>
            <div className="clock_wise_feature_item">
              <div className="heading">
                <div className="img">
                  <img src={integration_biometric_devices} alt="" />
                </div>
                <div className="title_head">
                  <p>Integration with Biometric Devices</p>
                </div>
              </div>
              <div className="desc">
                <ul>
                  <li>
                    Connect with biometric devices for secure and accurate
                    attendance recording.
                  </li>
                  <li>
                    Support for fingerprint, facial recognition, or other
                    biometric methods.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact_section" className="section" ref={ref}>
        <div className="my_container">
          <div className="form_con">
            <div className="info">
              <img src={wakaLight} alt="" />
              <h5>
                Request a <span className="colorRed">Demo</span>
              </h5>
              <p>
                Thank you for your interest on WAKA System. Please fill out the
                form below, and our team will be in touch to schedule a
                demonstration.
              </p>
            </div>
            {form.formState ? (
              <div
                className="form_fields"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Alert severity="success" sx={{ mt: 2 }}>
                  Form submitted successfully.
                </Alert>
              </div>
            ) : (
              <div className="form_fields">
                <h5>Contact Information</h5>
                <div className="field">
                  <label htmlFor="">Name</label>
                  <TextField
                    disabled={callingApi ? true : false}
                    variant="outlined"
                    size="small"
                    // value={form.name}
                    defaultValue={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                  />
                </div>
                <div className="field">
                  <label htmlFor="">Company Name</label>
                  <TextField
                    disabled={callingApi ? true : false}
                    variant="outlined"
                    size="small"
                    // value={form.comName}
                    defaultValue={form.comName}
                    onChange={(e) =>
                      setForm({ ...form, comName: e.target.value })
                    }
                  />
                </div>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                  className="email_phone_con"
                >
                  <div className="field" style={{ marginRight: "10px" }}>
                    <label htmlFor="">Email</label>
                    <TextField
                      disabled={callingApi ? true : false}
                      variant="outlined"
                      size="small"
                      defaultValue={form.email}
                      // value={form.email}
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="">Phone Number</label>
                    <TextField
                      disabled={callingApi ? true : false}
                      variant="outlined"
                      size="small"
                      // value={form.phoneNumber}
                      defaultValue={form.phoneNumber}
                      onChange={(e) =>
                        setForm({ ...form, phoneNumber: e.target.value })
                      }
                    />
                  </div>
                </Stack>
                <div className="field">
                  <label htmlFor="">Note</label>
                  <TextField
                    disabled={callingApi ? true : false}
                    variant="outlined"
                    size="small"
                    multiline
                    rows={4}
                    // value={form.note}
                    defaultValue={form.note}
                    onChange={(e) => setForm({ ...form, note: e.target.value })}
                  />
                </div>
                <AppButton
                  disabled={callingApi ? true : false}
                  text={callingApi ? "Submitting...." : "Submit"}
                  color="error"
                  btnClass={"submitForm"}
                  onClick={() => {
                    saveForm();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </section>
      <section id="footer_section" className="section">
        <div className="my_container">
          {/* <div className="req_demo">
            <div className="text">
              <h3>Sed ut perspiciatis unde omnis iste natus error sit</h3>
            </div>
            <div className="buttonCon">
              <div className="d_block">
                <AppButton
                  text={"Request Demo"}
                  color="error"
                  onClick={reqDemo}
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className="my_container">
          <p className="copyright">
            Copyright © 2023 by WAKA. All rights reserved.
          </p>
        </div>
      </section>
      <Snackbar
        open={snackMessage.state}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackMessage.severity}
          sx={{ width: "100%" }}
        >
          {snackMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
}
